.modal {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.65);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5em 1em;
    z-index: 999999;
    box-sizing: border-box;
  }
  
  .modal > .modal-fade {
    animation: fade-in 1s 1 linear;
    animation-fill-mode: forwards;
    opacity: 0;
  }
  
  .modal > .modal-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .modal-close {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #ffffff;
    cursor: pointer;
    font-size: 1.25em;
    background: rgb(156, 0, 0);
    border: 1px solid #c3c0c0;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
    box-sizing: border-box;
    display: inline-block;
    text-align: center;
  }
  
  .modal-close:hover {
    background: rgb(121, 15, 15);
  }
  
  .modal > .modal-body {
    max-width: 1000px;
    z-index: 2;
    position: relative;
    margin: 50px auto;
    background: #e4e4e4 url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Warstwa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 640 120' style='enable-background:new 0 0 640 120;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .snowdrift1%7Bfill:%23EDF8FE;%7D .snowdrift2%7Bfill:%23F5FBFE;%7D%0A%3C/style%3E%3Cpath class='snowdrift1' d='M630.9,79.8c-0.3-25.2-29.4-47.6-55.8-55.3c-6.9-2-30.2-8.8-52.6,1.3c-22.1,10-19.5,25.8-44.3,40.1 c-6.8,3.9-33.4,19.2-51.2,9.7c-14-7.5-13.9-25.6-37.9-53.7c-2.5-2.9-5.9-6.8-11.4-7.9c-11.5-2.4-22.4,8.9-32.5,17.6 c-18,15.6-39,27.8-51.2,32.2c-31,11.2-65.4-5.9-98.8-22.5C149.2,18.3,143.2,0,122,2.9C90.4,7.1,92.3,49.3,56.6,57.5 c-33.2,7.6-61.5-22.5-87.8-6.2c-6.2,3.9-10,8.9-11.9,11.5c0,0-15,20.2-8.7,52c13.6,68.8,604.9,66,668.9-4.4 C631.1,95,630.9,79.8,630.9,79.8z'/%3E%3Cpath class='snowdrift2' d='M-9.2,94.5c0.3-25.2,29-47.6,55-55.3c6.8-2,29.8-8.8,51.7,1.3c21.7,10,19.2,25.8,43.6,40.1 c6.7,3.9,32.8,19.2,50.4,9.7c13.8-7.5,13.7-25.6,37.3-53.7c2.5-2.9,5.8-6.8,11.2-7.9c11.3-2.4,22,8.9,31.9,17.6 c17.7,15.6,38.4,27.8,50.4,32.2c30.5,11.2,64.4-5.9,97.2-22.5c45.2-22.9,51.1-41.2,72-38.3c31.1,4.2,29.2,46.4,64.3,54.6 c32.7,7.6,60.5-22.5,86.4-6.2c6.1,3.9,9.8,8.9,11.7,11.5c0,0,14.7,20.2,8.5,52c-13.4,68.8-595.3,66-658.2-4.4 C-9.3,109.7-9.2,94.5-9.2,94.5z'/%3E%3C/svg%3E%0A") no-repeat;
    background-position-y: bottom;
    background-size: 100%;
    border-radius: 5px;
    max-height: 90vh;
    padding: 60px 30px 30px 30px;
    color: #000000;
    text-align: center;
    animation: blowUpModal .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    
  }

  .modal-description {
    padding: 15px 0;
  }

  .modal-container {
    overflow-x: auto;
  }

  .modal-date {
    font-size: 1.9rem;
    font-weight: 700;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    color: #fff;
    background-color: #F16368;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    text-align: center;
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translate(-50%, 0);
    -webkit-box-shadow: 1px 4px 18px -9px rgba(77, 78, 86, 1);
    -moz-box-shadow: 1px 4px 18px -9px rgba(77, 78, 86, 1);
    box-shadow: 1px 4px 18px -9px rgba(77, 78, 86, 1);
  }

  .modal-source {
    font-size: 0.7rem;
    color: #616161;
    text-align: right;
  }
  
  .leaf{
    background: url('./img/leaf.svg') no-repeat;
    background-size: contain;
    width: 25px;
    height: 15px;
    background-position: center;
    width: 25px;
    height: 15px;
    
  }

  .deer {
    position: relative;
    bottom: -34px;
  }

  .leaf.leaf-right {
    transform: rotate(90deg);
  }

  .leaf.leaf.leaf-left {
    transform: rotate(290deg);
  }
  
  @keyframes fade-in {
    0% {
      animation-timing-function: cubic-bezier(0.2242, 0.7499, 0.3142, 0.8148);
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes blowUpModal {
    0% {
      transform:scale(0);
    }
    100% {
      transform:scale(1);
    }
  }

  @keyframes closeModal {
    0% {
      transform:scale(1);
      opacity:1;
    }
    100% {
      transform:scale(0);
      opacity:0;
    }
  }